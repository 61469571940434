<template>
  <div class="container">
    <img @click="toIndex" class="loginPic" src="@/assets/img/logo.png" alt="">
    <div class="form">
      <div class="right">
        <span class="green">立即注册</span>
        <img class="arrow" @click="goBack" src="../../assets/img/arrow.png" />
        <div class="content">
          <div class="item">
            <span class="svg-container login-user"></span>
            <input placeholder="请输入手机号" v-model="phone" />
          </div>
          <div class="item">
            <span class="svg-container login-password"></span>
            <input placeholder="请输入验证码" v-model="getSatr" />
          </div>
          <div class="item">
            <span class="svg-container login-user"></span>
            <input type="password" placeholder="请输入密码" v-model="password" />
          </div>
          <span class="getMsg" v-show="show" @click="getCode">获取验证码</span>
          <span v-show="!show" class="getMsg">{{ count }} s</span>
        </div>
        <p class="wei">
          <input class="check" type="checkbox" v-model="checked" />我已阅读并同意<font
            style="color: #097638"
            >用户协议</font
          >和<font style="color: #097638">隐私政策</font>
        </p>
        <button class="btn" @click="login">登录</button>

        <span class="lfa" @click="loginSao">扫码登录</span>
        <span class="rta" @click="forgetCode">忘记密码</span>
      </div>
    </div>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { registerLogin,getSalt,sendCode } from '@/common/public.js'
import MD5 from '@/common/md5.js'
import commonFooter from '@/components/loginFooter'
export default {
  name: "login",
  components: {commonFooter},
  data() {
    return {
      lftext: "忘记密码？",
      scaner: require("../../assets/img/scaner.png"),
      qrscaner: require("../../assets/img/qrcodebg.png"),
      active: true,
      show: true,
      count: "",
      timer: null,
      hasError: false,
      status: "pwdlogin",
      phone:'',//手机号
      getSatr:'',//盐值
      password:'',//密码
      saltCode:'',//短信验证码
      checked:null,//点击已同意阅读
      urlArr:[],//路由数组
      urlIndex:0,//url下标
    };
  },
  mounted() {},
  created(){
    this.urlArr = this.$store.state.routePath;
    this.urlIndex = this.urlArr.findIndex((value)=>{
      return value.url == '/login'
    })
  },
  methods: {
    //点击logo跳转到首页
    toIndex(){
      this.$router.push({
        path:'/index'
      })
    },
    loginSao() {
      this.$router.push({ path: "/login", query: { flag: 1 } });
    },
    forgetCode() {
      this.$router.push("/forgetcode");
    },
    //获取盐值接口
    getSlcode(){
      getSalt().then(res=>{
        if(res.code == 200){
          this.saltCode = res.result.domain.saltCode;
          this.sendCodeRequest()
        }
      })
    },
    //短信验证码
    sendCodeRequest(){
        let phone = this.phone;
        let timestamp = Date.parse(new Date());
        let messageKey = 'TongLiao&&567_NM_yingguoyingyuyuyan%%';
        let saltCode = this.saltCode;
        let sign = MD5.md5(phone+timestamp+messageKey+saltCode)
        let messageType  = 'register';
        let data = {phone,timestamp,sign,saltCode,messageType}
        sendCode(data).then(res=>{
          if(res.code == 200){
            this.timeEnd()
          }
        })
    },
    //倒计时
    timeEnd(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }else{
        console.log(' ') 
      }
    },
    //点击获取验证码
    getCode() {
      //循环调用包装方法
        this.getSlcode()
    },
    goBack(){
        this.$router.go(-1);//返回上一层
    },
    //点击登录
    login() {
      let data = {
          phone:this.phone,
          code:this.getSatr,
          password:this.password
      };
      //验证手机号
      var regs = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
      let phones = new RegExp(regs)
      let userPhone = phones.test(this.phone)
      //验证密码
      var reg =/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
      var re = new RegExp(reg)
      let regPassword = re.test(this.password);
      if(!this.phone){
        this.$message({
          showClose: true,
          message: '请输入手机号',
          type: 'error'
        });
      }else if(!userPhone){
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'error'
        });
      }else if(!this.getSatr){
        this.$message({
          showClose: true,
          message: '请输入验证码',
          type: 'error'
        });
      }else if(!regPassword){
        this.$message({
          showClose: true,
          message: '密码必须包含字母和数字，长度为6-16位',
          type: 'error'
        });
      }else if(!this.checked){
        this.$message({
          showClose: true,
          message: '请您勾选用户协议',
          type: 'error'
        });
      }else{
          registerLogin(data).then(res=>{
            if(res.code == 200){
              let user = res.result.domain;
              this.$store.commit('login',user)
              if(this.urlIndex == -1){
              this.$router.go(-1)
              this.$store.commit('removePath')
              }else{
                this.$router.push({
                  path:this.urlArr[this.urlIndex-1].url
                })
                this.$store.commit('removePath')
              }
              this.$message({
                showClose: true,
                message: '登陆成功',
                type: 'success'
              });
            }else{
              this.$message({
                showClose: true,
                message: res.message,
                type: 'warning'
              });
            }
          })
      }
    },
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
<style scoped lang="scss">
.loginPic{
  position: absolute;
  top: 0;
  left: 0;
  width: 142px;
  height: 90px;
  cursor: pointer;
}
.lf {
  cursor: pointer;
  padding-bottom: 20px;
  float: left;
  width: 50%;
  padding: 0 10px 15px 45px;
}

.lfa {
  cursor: pointer;
  padding-bottom: 10px;
  float: left;
  width: 50%;
  font-size: 12px;
  color: #666666;
  padding: 15px 0;
}
.rta {
  cursor: pointer;
  width: 50%;
  font-size: 12px;
  color: #666666;
  padding: 15px 0;
  float: right;
}
.loginimg {
  height: 82px;
}
.rt {
  cursor: pointer;
  padding-bottom: 20px;
  width: 50%;
  padding: 0 45px 15px 10px;
  float: right;
}
input {
  outline: none;
  height: 50px;
  border-bottom: 1px solid #cccccc;
  border-top: none;
  border-left: none;
  width: 80%;
  border-right: none;
}
input[type=checkbox]::after{
    position: absolute;
    top: -2px;
    color: #000;
    width: 14px;
    height: 14px;
    display: inline-block;
    visibility: visible;
    text-align: center;
    content: " ";
    border-radius: 3px;
    background: #fff;
    border: 1px solid #ccc;
}
input[type=checkbox]:checked::after{
     content: "✓";
     font-size:12px;
     color:#fff;
     background-color: #097638;
}
.container {
  text-align: center;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  background: url("../../assets/img/bg.png") center center no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.form {
  margin-top: 10%;
  margin-left: 50%;
  width: 300px;
  height: 360px;
}
.qrcodebg {
  width: 82px;
  height: 82px;
  float: right;
}
.wen {
  position: relative;
  font-size: 12px;
  top: -18px;
  left: 8px;
  text-align: left;
}
.scaner {
  width: 84px;
  height: auto;
  margin-top: 12px;
  margin-left: 86px;
}
.right {
  padding-top: 20px;
  background: #ffffff;
  border-radius: 4px;
  width: 300px;
  height: 360px;
  margin-left: -150px;
}
.login-form {
  width: 350px;
  margin: 0 auto;
}
.green {
  display: block;
  font-weight: bold;
  color: #097638;
}
.btn {
  color: #fff;
  width: 260px;
  outline:none;
  filter: chroma(color=#000000);
  height: 40px;
  background: #097638;
  border: none;
  border-radius: 2px;
}
.saoimg {
  width: 30px;
  height: 30px;
}
.getMsg {
  position: relative;
  top: -100px;
  display: block;
  left: 178px;
  background: #fff;
  height: 48px;
  cursor: pointer;
  line-height: 50px;
  width: 108px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.content {
  height: 158px;
}
input:focus {
  outline: none;
}
.arrow {
  position: relative;
  left: -114px;
  width: 24px;
  top:-20px;
  height: 18px;
}
.check {
  position: relative;
  margin: 20px 3px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.wei {
  // margin-left: 5%;
  padding-left: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
</style>